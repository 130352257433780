<template>
  <div class="worldcup">
    <div class="header" id="headerPc">
      <div style="display: flex" v-if="decipherment">
        <img
          @click="index"
          class="logo"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
          alt=""
        />
        <div class="title engreg" @click="index" style="margin-left: 60px">
          HOME
        </div>
        <div class="title engreg" @click="party">PARTY</div>
        <div class="title engreg" @click="stake">STAKING</div>
        <div class="title engreg" @click="refresh">GAMBLER HALL</div>
        <!-- <div class="title engreg" @click="changeCloth">World Cup Outfits</div> -->
        <div class="title engreg" @click="starry">3D PARTY</div>
      </div>
      <div style="display: flex" v-else>
        <img
          @click="index"
          class="logo"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
          alt=""
        />
        <div class="title chinreg" style="margin-left: 60px" @click="index">
          首页
        </div>
        <div class="title chinreg" @click="party">PARTY</div>
        <div class="title chinreg" @click="stake">质押</div>
        <div class="title chinreg" @click="refresh">世界杯赌王</div>
        <!-- <div class="title chinreg" @click="changeCloth">世界杯换装</div> -->
        <div class="title chinreg" @click="starry">3D PARTY</div>
      </div>
      <ul class="header_right">
        <li class="connect" v-if="connected">
          <div class="hover_box1 engBold">
            {{ walletAddress }}
          </div>
        </li>
        <li class="connect1" v-else @click="logIn">
          <p class="engBold" v-if="decipherment">CONNECT WALLET</p>
          <p class="chinBold headermar" v-else>连接钱包</p>
        </li>
        <li
          class="title1"
          @click="revise"
          :class="decipherment ? 'engreg' : 'chinreg'"
        >
          {{ eng }}
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xia.png"
            alt=""
          />
          <div class="cha_text" v-show="revise_text" @click="deciphermented">
            {{ chin }}
          </div>
        </li>
        <li class="img1" @click="openseaview">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/opensea1.png"
            alt=""
            srcset=""
          />
        </li>
        <li class="img">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitter1.png"
            alt=""
            srcset=""
            @click="twitter"
          />
        </li>
        <li class="img">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
            alt=""
            srcset=""
            @click="redirect"
          />
        </li>
      </ul>
    </div>
    <div class="headerM" id="headerMM">
      <div>
        <img
          @click="index"
          class="logo"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
          alt=""
        />
      </div>
      <div class="headerM_ul">
        <div class="butbox">
          <div class="connect" v-if="connected">
            <div class="hover_box1 engBold">
              {{ walletAddress }}
            </div>
          </div>
          <div class="connect1" v-else @click="logIn">
            <p class="engBold" v-if="decipherment">CONNECT</p>
            <p class="chinBold" v-else>连接钱包</p>
          </div>
        </div>
        <div class="menubox" @click="menu">
          <img
            class="menu"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/menu.png"
            alt=""
          />
          <div class="del" v-if="menudel">
            <div v-if="decipherment">
              <p class="text engreg" @click="index">HOME</p>
              <p class="text engreg" @click="party">PARTY</p>
              <p class="text engreg" @click="stake">STAKING</p>
              <p class="text engreg" @click="refresh">GAMBLE</p>
              <!-- <p class="text engreg" @click="changeCloth">OUTFITS</p> -->
              <p class="text engreg" @click="starry">3D PARTY</p>
              <p class="text engreg" @click="openseaview">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png"
                  alt=""
                  srcset=""
                />
                <span>OPENSEA</span>
              </p>
              <p class="text engreg" @click="twitter">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png"
                  alt=""
                  srcset=""
                />
                <span>TWITTER</span>
              </p>
              <p class="text engreg" @click="redirect">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
                  alt=""
                  srcset=""
                />
                <span>DISCORD</span>
              </p>
              <p class="text engreg" @click="deciphermented">中文</p>
            </div>
            <div v-else>
              <p class="text chinreg" @click="index">首页</p>
              <p class="text chinreg" @click="party">PARTY</p>
              <p class="text chinreg" @click="stake">质押</p>
              <p class="text chinreg" @click="refresh">世界杯赌王</p>
              <!-- <p class="text chinreg" @click="changeCloth">世界杯换装</p> -->
              <p class="text chinreg" @click="starry">3D PARTY</p>
              <p class="text engreg" @click="openseaview">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png"
                  alt=""
                  srcset=""
                />
                <span>OPENSEA</span>
              </p>
              <p class="text engreg" @click="twitter">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png"
                  alt=""
                  srcset=""
                />
                <span>TWITTER</span>
              </p>
              <p class="text engreg" @click="redirect">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
                  alt=""
                  srcset=""
                />
                <span>DISCORD</span>
              </p>
              <p class="text chinreg" @click="deciphermented">ENGLISH</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="banner">
      <p>
        <img
          class="banner_img"
          src="/assets/icon/worldCup/banner.png"
          alt=""
          srcset=""
        />
      </p>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">
          Welcome to the World Cup Gambler Hall! First of all... Thank you,
          Italy, for not eliminating my county since YOU DIDN'T MAKE IT!
        </p>
        <p v-else class="chinreg">
          欢迎来到世界杯现场背后的北海慈善赌王大厅！什么？你在现场看到了意大利国旗？你不会是红绿色盲吧？
        </p>
      </div>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">Game rules:</p>
        <!-- <span style="color: #3395a0; font-weight: bold"> your own bus</span> -->
        <p v-else class="chinreg">游戏规则：</p>
      </div>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">
          1. The pool size of each match depends on the total number of bottle
          caps putted in by participants.
        </p>
        <p v-else class="chinreg">
          1. 每场竞猜总奖池为当场参与人数的总投入瓶盖数
        </p>
      </div>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">
          2. The winning side will distribute the total prize pool of the losing
          side according to the ratio.
        </p>
        <p v-else class="chinreg">
          2. 获胜者将按照下注比例来分配失败方的总奖池
        </p>
      </div>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">
          3. No more Beer Caps raising in the middle of a match.
        </p>
        <p v-else class="chinreg">3. 禁止中途加注</p>
      </div>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">4. No betting on both side!</p>
        <p v-else class="chinreg">4. 禁止对赌</p>
      </div>
    </div>
    <div>
      <div class="hengfu engBold" v-if="connected">
        <div class="hengfu_center">
          <div class="hengfu_left">
            <div class="hi">
              <img
                class="hi"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hi.png"
                alt=""
                srcset=""
                v-if="decipherment"
              />
              <img
                class="hi1"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hi1.png"
                alt=""
                srcset=""
                v-else
              />
            </div>
            <div class="adress engreg">{{ walletAddress }}</div>
          </div>
          <div class="xian"></div>
          <div class="hengfu_right">
            <div class="hengfu_rightli hengfu_rightli1">
              <div class="title title2">
                <p v-if="decipherment" class="engBold">BEER CAPS</p>
                <p v-else class="chinBold">瓶盖</p>
              </div>
              <div class="text1 engBold">
                <p class="imgged">
                  <img src="/assets/icon/worldCup/cap.png" alt="" />
                </p>
                <p>{{ information_list.total_caps }}</p>
              </div>
            </div>
            <div class="hengfu_rightli hengfu_rightli2">
              <div>
                <div class="text">
                  <div class="title ppp">
                    <p v-if="decipherment" class="engBold"># Gambled Games</p>
                    <p v-else class="chinBold">参与竞猜场次</p>
                  </div>
                  <div class="title mmm">
                    <p v-if="decipherment" class="engBold">#Games</p>
                    <p v-else class="chinBold">游戏</p>
                  </div>
                  <div class="history" @click="history_true">
                    <p v-if="decipherment" class="engreg">History</p>
                    <p v-else class="chinreg history_text">历史</p>
                    <p class="xian"></p>
                  </div>
                </div>
                <div class="text1 engBold">
                  {{ information_list.gambled_games }}
                </div>
              </div>
            </div>
            <div class="hengfu_rightli hengfu_rightli3">
              <div>
                <div class="text">
                  <div class="title">
                    <p v-if="decipherment" class="engBold">Profit</p>
                    <p v-else class="chinBold">瓶盖总获利</p>
                  </div>
                  <div class="history" @click="ranking_true">
                    <p v-if="decipherment" class="engreg">Ranking</p>
                    <p v-else class="chinreg history_text">赌王排行榜</p>
                    <p class="xian"></p>
                  </div>
                </div>
                <div class="text1 engBold">
                  <!-- <div> -->
                  <p v-if="information_list.profit >= 0" class="titlewin">
                    +{{ information_list.profit }}
                  </p>
                  <p v-else class="titlewin">{{ information_list.profit }}</p>
                  <p class="imgged">
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <!-- </div>
                  <div v-else class="titlelose">
                    <p>{{ information_list.profit }}</p>
                    <p>
                      <img src="/assets/icon/worldCup/cap.png" alt="" />
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- {{ walletAddress }} -->
      </div>
      <div class="hengfu1" v-else @click="logIn">
        <div class="conner">
          <p class="engBold" v-if="decipherment">
            <span class="connect_true">CONNECT WALLET</span>
            <span class="connect_true_m">CONNECT</span>
          </p>
          <p class="chinBold" v-else>连接钱包</p>
        </div>
      </div>
    </div>
    <div
      v-if="connected"
      class="text_big"
      :class="selectPage == 0 ? 'text_big_b1' : 'text_big_b2'"
    >
      <div class="select">
        <div class="select_left" @click="select_page(0)">
          <p
            :class="selectPage == 0 ? 'select_leftpco1' : 'select_leftpco'"
            class="select_leftp engBold"
            v-if="decipherment"
          >
            Ongoing Bets
          </p>
          <p
            :class="selectPage == 0 ? 'select_leftpco1' : 'select_leftpco'"
            v-else
            class="select_leftp chinBold"
          >
            可下注的场次
          </p>
        </div>
        <div class="select_left" @click="select_page(1)">
          <p
            :class="selectPage == 1 ? 'select_leftpco1' : 'select_leftpco'"
            v-if="decipherment"
            class="select_leftp engBold"
          >
            History
          </p>
          <p
            :class="selectPage == 1 ? 'select_leftpco1' : 'select_leftpco'"
            v-else
            class="select_leftp chinBold"
          >
            场次历史记录
          </p>
        </div>
      </div>
      <div class="processed" v-show="selectPage == 0">
        <div v-for="(item, key) in progress_list" :key="key">
          <div class="processed_li">
            <div class="logo_del">
              <p>
                <img class="logo" :src="item.event1.url" alt="" />
              </p>
              <div class="name_cup">
                <p v-if="decipherment" class="engreg">
                  {{ item.event1.name_en }}
                </p>
                <p v-else class="chinreg">
                  {{ item.event1.name }}
                </p>
              </div>
              <div class="name_bir chinBold">
                <!-- <div v-if="item.event1.chip == 0">
                  <p v-if="decipherment" class="engBold">Available to Bet</p>
                  <p v-else class="chinBold">未投注</p>
                </div> -->
                <div class="beercap">
                  <p v-if="decipherment" class="engBold">Beer Caps in Pool：</p>
                  <p v-else class="chinBold">投注池里有：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p class="engreg">{{ item.event1.chip }}</p>
                </div>
              </div>
              <div
                :class="item.can_betting == 0 ? 'bettinged' : 'betting'"
                @click="betting_Box(item.can_betting, item.id, 1)"
              >
                <p v-if="decipherment" class="engBold">Offer</p>
                <p v-else class="chinBold">投注</p>
              </div>
              <div class="touzhu chinreg" v-if="item.event1.self_chip != 0">
                <div v-if="decipherment" class="engreg touzhubox">
                  <p>(You've betted：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p>{{ item.event1.self_chip }})</p>
                </div>
                <div v-else class="chinreg touzhubox">
                  <p>(已投注：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p>{{ item.event1.self_chip }})</p>
                </div>
              </div>
            </div>
            <div class="vs">
              <p class="vs_title engreg">VS</p>
              <p class="vs_time engreg">{{ dataTime(item.start_time) }}</p>
            </div>
            <div class="logo_del">
              <p>
                <img class="logo" :src="item.evnet2.url" alt="" />
              </p>
              <div class="name_cup">
                <p v-if="decipherment" class="engreg">
                  {{ item.evnet2.name_en }}
                </p>
                <p v-else class="chinreg">
                  {{ item.evnet2.name }}
                </p>
              </div>
              <div class="name_bir chinBold">
                <!-- <div v-if="item.evnet2.chip == 0">
                  <p v-if="decipherment" class="engBold">Available to Bet</p>
                  <p v-else class="chinBold">未投注</p>
                </div> -->
                <div class="beercap">
                  <p v-if="decipherment" class="engBold">Beer Caps in Pool：</p>
                  <p v-else class="chinBold">投注池里有：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p class="engreg">{{ item.evnet2.chip }}</p>
                </div>
              </div>
              <div
                :class="item.can_betting == 0 ? 'bettinged' : 'betting'"
                @click="betting_Box(item.can_betting, item.id, 2)"
              >
                <p v-if="decipherment" class="engBold">Offer</p>
                <p v-else class="chinBold">投注</p>
              </div>
              <div class="touzhu chinreg" v-if="item.evnet2.self_chip != 0">
                <div v-if="decipherment" class="engreg touzhubox">
                  <p>(You've betted：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p>{{ item.evnet2.self_chip }})</p>
                </div>
                <div v-else class="chinreg touzhubox">
                  <p>(已投注：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p>{{ item.evnet2.self_chip }})</p>
                </div>
              </div>
            </div>
          </div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="processed" v-show="selectPage == 1">
        <div v-for="(item, key) in history_list" :key="key">
          <div class="processed_li">
            <div class="logo_del">
              <p>
                <img class="logo" :src="item.event1.url" alt="" />
              </p>
              <div class="name_cup">
                <p v-if="decipherment" class="engreg">
                  {{ item.event1.name_en }}
                </p>
                <p v-else class="chinreg">
                  {{ item.event1.name }}
                </p>
              </div>
              <div class="name_bir chinBold">
                <!-- <div v-if="item.event1.chip == 0">
                  <p v-if="decipherment" class="engBold">Available to Bet</p>
                  <p v-else class="chinBold">未投注</p>
                </div> -->
                <div class="beercap">
                  <p v-if="decipherment" class="engBold">Beer Caps in Pool：</p>
                  <p v-else class="chinBold">投注池里有：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p class="engreg">{{ item.event1.chip }}</p>
                </div>
              </div>
              <div v-if="item.winner == 0" class="pro">
                <p v-if="decipherment" class="engBold">Processing</p>
                <p v-else class="chinBold">处理中</p>
              </div>
              <div v-if="item.winner == 1" class="win">
                <p v-if="decipherment" class="engBold">WIN</p>
                <p v-else class="chinBold">胜</p>
              </div>
              <div v-if="item.winner == 2" class="lose">
                <p v-if="decipherment" class="engBold">LOSE</p>
                <p v-else class="chinBold">负</p>
              </div>
              <div class="touzhu" v-if="item.event1.self_chip != 0">
                <div v-if="decipherment" class="engreg touzhubox">
                  <p>(You've betted：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p>{{ item.event1.self_chip }})</p>
                </div>
                <div v-else class="chinreg touzhubox">
                  <p>(已投注：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p>{{ item.event1.self_chip }})</p>
                </div>
              </div>
            </div>
            <div class="vs">
              <p class="vs_title engreg">VS</p>
              <p class="vs_time engreg">{{ dataTime(item.start_time) }}</p>
            </div>
            <div class="logo_del">
              <p>
                <img class="logo" :src="item.evnet2.url" alt="" />
              </p>
              <div class="name_cup engreg">
                <p v-if="decipherment" class="engreg">
                  {{ item.evnet2.name_en }}
                </p>
                <p v-else class="chinreg">
                  {{ item.evnet2.name }}
                </p>
              </div>
              <div class="name_bir chinBold">
                <!-- <div v-if="item.evnet2.chip == 0">
                  <p v-if="decipherment" class="engBold">Available to Bet</p>
                  <p v-else class="chinBold">未投注</p>
                </div> -->
                <div class="beercap">
                  <p v-if="decipherment" class="engBold">Beer Caps in Pool：</p>
                  <p v-else class="chinBold">投注池里有：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p class="engreg">{{ item.evnet2.chip }}</p>
                </div>
              </div>
              <div v-if="item.winner == 0" class="pro">
                <p v-if="decipherment" class="engBold">Processing</p>
                <p v-else class="chinBold">处理中</p>
              </div>
              <div v-if="item.winner == 2" class="win">
                <p v-if="decipherment" class="engBold">WIN</p>
                <p v-else class="chinBold">胜</p>
              </div>
              <div v-if="item.winner == 1" class="lose">
                <p v-if="decipherment" class="engBold">LOSE</p>
                <p v-else class="chinBold">负</p>
              </div>
              <div class="touzhu" v-if="item.evnet2.self_chip != 0">
                <div v-if="decipherment" class="engreg touzhubox">
                  <p>(You've betted：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p>{{ item.evnet2.self_chip }})</p>
                </div>
                <div v-else class="chinreg touzhubox">
                  <p>(已投注：</p>
                  <p>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                  </p>
                  <p>{{ item.evnet2.self_chip }})</p>
                </div>
              </div>
            </div>
          </div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="processed_m" v-show="selectPage == 0">
        <div v-for="(item, key) in progress_list" :key="key">
          <div class="processed_mli">
            <div class="processed_mlileft">
              <p class="left_img">
                <img :src="item.event1.url" alt="" />
              </p>
              <div class="left_name engreg">
                <p v-if="decipherment" class="engreg">
                  {{ item.event1.name_en }}
                </p>
                <p v-else class="chinreg">
                  {{ item.event1.name }}
                </p>
              </div>
              <div class="left_name engBold">
                <!-- <div v-if="item.event1.chip == 0">
                  <p v-if="decipherment" class="engBold">Available to Bet</p>
                  <p v-else class="chinBold">未投注</p>
                </div> -->
                <div>
                  <div v-if="decipherment" class="engBold">
                    <span>Beer Caps in Pool：</span>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                    <span>{{ item.event1.chip }}</span>
                  </div>
                  <div v-else class="chinBold">
                    <span>投注池里有：</span>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                    <span>{{ item.event1.chip }}</span>
                  </div>
                </div>
              </div>
              <div
                :class="item.can_betting == 0 ? 'left_btned' : 'left_btn'"
                @click="betting_Box(item.can_betting, item.id, 1)"
              >
                <p v-if="decipherment" class="engBold">Offer</p>
                <p v-else class="chinBold">投注</p>
              </div>
              <div class="left_yitou" v-if="item.event1.self_chip != 0">
                <p v-if="decipherment" class="engreg">
                  <span>(You've betted：</span>
                  <img src="/assets/icon/worldCup/cap.png" alt="" />
                  <span>{{ item.event1.self_chip }})</span>
                </p>
                <p v-else class="chinreg">
                  <span>(已投注：</span>
                  <img src="/assets/icon/worldCup/cap.png" alt="" />
                  <span>{{ item.event1.self_chip }})</span>
                </p>
              </div>
            </div>
            <div class="processed_mlivs">
              <p class="left_vs engreg">VS</p>
              <p class="left_time engreg">{{ dataTime(item.start_time) }}</p>
            </div>
            <div class="processed_mlileft">
              <p class="left_img">
                <img :src="item.evnet2.url" alt="" />
              </p>
              <div class="left_name">
                <p v-if="decipherment" class="engreg">
                  {{ item.evnet2.name_en }}
                </p>
                <p v-else class="chinreg">
                  {{ item.evnet2.name }}
                </p>
              </div>
              <div class="left_name engBold">
                <!-- <div v-if="item.evnet2.chip == 0">
                  <p v-if="decipherment" class="engBold">Available to Bet</p>
                  <p v-else class="chinBold">未投注</p>
                </div> -->
                <div>
                  <div v-if="decipherment" class="engBold">
                    <span>Beer Caps in Pool：</span>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                    <span>{{ item.evnet2.chip }}</span>
                  </div>
                  <div v-else class="chinBold">
                    <span>投注池里有：</span>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                    <span>{{ item.evnet2.chip }}</span>
                  </div>
                </div>
              </div>
              <div
                :class="
                  item.can_betting == 0
                    ? 'left_btned chinBold'
                    : 'left_btn chinBold'
                "
                @click="betting_Box(item.can_betting, item.id, 2)"
              >
                <p v-if="decipherment" class="engBold">Offer</p>
                <p v-else class="chinBold">投注</p>
              </div>
              <div class="left_yitou" v-if="item.evnet2.self_chip != 0">
                <p v-if="decipherment" class="engreg">
                  <span>(You've betted：</span>
                  <img src="/assets/icon/worldCup/cap.png" alt="" />
                  <span>{{ item.evnet2.self_chip }})</span>
                </p>
                <p v-else class="chinreg">
                  <span>(已投注：</span>
                  <img src="/assets/icon/worldCup/cap.png" alt="" />
                  <span>{{ item.evnet2.self_chip }})</span>
                </p>
              </div>
            </div>
          </div>
          <div class="left_xian"></div>
        </div>
      </div>
      <div class="processed_m" v-show="selectPage == 1">
        <div v-for="(item, key) in history_list" :key="key">
          <div class="processed_mli">
            <div class="processed_mlileft">
              <p class="left_img">
                <img :src="item.event1.url" alt="" />
              </p>
              <div class="left_name">
                <p v-if="decipherment" class="engreg">
                  {{ item.event1.name_en }}
                </p>
                <p v-else class="chinreg">
                  {{ item.event1.name }}
                </p>
              </div>
              <div class="left_name engBold">
                <!-- <div v-if="item.event1.chip == 0">
                  <p v-if="decipherment" class="engBold">Available to Bet</p>
                  <p v-else class="chinBold">未投注</p>
                </div> -->
                <div>
                  <div v-if="decipherment" class="engBold">
                    <span>Beer Caps in Pool：</span>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                    <span>{{ item.event1.chip }}</span>
                  </div>
                  <div v-else class="chinBold">
                    <span>投注池里有：</span>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                    <span>{{ item.event1.chip }}</span>
                  </div>
                </div>
              </div>
              <div v-if="item.winner == 0" class="left_pro chinBold">
                <p v-if="decipherment" class="engBold">Processing</p>
                <p v-else class="chinBold">处理中</p>
              </div>
              <div v-if="item.winner == 1" class="left_win chinBold">
                <p v-if="decipherment" class="engBold">WIN</p>
                <p v-else class="chinBold">胜</p>
              </div>
              <div v-if="item.winner == 2" class="left_lose chinBold">
                <p v-if="decipherment" class="engBold">LOSE</p>
                <p v-else class="chinBold">负</p>
              </div>
              <div class="left_yitou" v-if="item.event1.self_chip != 0">
                <p v-if="decipherment" class="engreg">
                  <span>(You've betted：</span>
                  <img src="/assets/icon/worldCup/cap.png" alt="" />
                  <span>{{ item.event1.self_chip }})</span>
                </p>
                <p v-else class="chinreg">
                  <span>(已投注：</span>
                  <img src="/assets/icon/worldCup/cap.png" alt="" />
                  <span>{{ item.event1.self_chip }})</span>
                </p>
              </div>
            </div>
            <div class="processed_mlivs">
              <p class="left_vs engreg">VS</p>
              <p class="left_time engreg">{{ dataTime(item.start_time) }}</p>
            </div>
            <div class="processed_mlileft">
              <p class="left_img">
                <img :src="item.evnet2.url" alt="" />
              </p>
              <div class="left_name engreg">
                <p v-if="decipherment" class="engreg">
                  {{ item.evnet2.name_en }}
                </p>
                <p v-else class="chinreg">
                  {{ item.evnet2.name }}
                </p>
              </div>
              <div class="left_name engBold">
                <!-- <div v-if="item.evnet2.chip == 0">
                  <p v-if="decipherment" class="engBold">Available to Bet</p>
                  <p v-else class="chinBold">未投注</p>
                </div> -->
                <div>
                  <div v-if="decipherment" class="engBold">
                    <span>Beer Caps in Pool：</span>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                    <span>{{ item.evnet2.chip }}</span>
                  </div>
                  <div v-else class="chinBold">
                    <span>投注池里有：</span>
                    <img src="/assets/icon/worldCup/cap.png" alt="" />
                    <span>{{ item.evnet2.chip }}</span>
                  </div>
                </div>
              </div>
              <div v-if="item.winner == 0" class="left_pro chinBold">
                <p v-if="decipherment" class="engBold">Processing</p>
                <p v-else class="chinBold">处理中</p>
              </div>
              <div v-if="item.winner == 2" class="left_win chinBold">
                <p v-if="decipherment" class="engBold">WIN</p>
                <p v-else class="chinBold">胜</p>
              </div>
              <div v-if="item.winner == 1" class="left_lose chinBold">
                <p v-if="decipherment" class="engBold">LOSE</p>
                <p v-else class="chinBold">负</p>
              </div>
              <div class="left_yitou" v-if="item.evnet2.self_chip != 0">
                <p v-if="decipherment" class="engreg">
                  <span>(You've betted：</span>
                  <img src="/assets/icon/worldCup/cap.png" alt="" />
                  <span>{{ item.evnet2.self_chip }})</span>
                </p>
                <p v-else class="chinreg">
                  <span>(已投注：</span>
                  <img src="/assets/icon/worldCup/cap.png" alt="" />
                  <span>{{ item.evnet2.self_chip }})</span>
                </p>
              </div>
            </div>
          </div>
          <div class="left_xian"></div>
        </div>
      </div>
    </div>
    <!-- 投注 -->
    <div class="betting_box" v-show="bettingBox">
      <div class="betting">
        <img
          class="cha"
          @click="close"
          src="/assets/icon/worldCup/cha.png"
          alt=""
        />
        <div class="please">
          <p v-if="decipherment" class="engBold">Choose your offer</p>
          <p v-else class="chinBold">请选择下注数量</p>
        </div>
        <div class="you_have">
          <span v-if="decipherment" class="engreg">You have</span>
          <span v-else class="chinreg">剩余的瓶盖数</span>
          <img src="/assets/icon/worldCup/cap.png" alt="" />
          {{ information_list.total_caps }}
        </div>
        <div class="input_ku">
          <p class="logo_input">
            <img src="/assets/icon/worldCup/cap.png" alt="" />
          </p>
          <div class="input_num">
            <input
              type="number"
              v-bind:value="cap_number"
              v-on:input="valueChange"
            />
          </div>
        </div>
        <div
          :class="caps_true ? 'touzhu_btn' : 'touzhu_btned '"
          @click="worldCupBetting(caps_true)"
        >
          <p v-if="decipherment" class="engBold">Offer</p>
          <p v-else class="chinBold">投注</p>
        </div>
      </div>
    </div>
    <!-- 历史&排名 -->
    <div class="ranking_box" v-show="rankingBox">
      <div
        class="text_big"
        :class="rankingPage == 0 ? 'text_big_b1' : 'text_big_b2'"
      >
        <img
          class="cha"
          @click="close_his"
          src="/assets/icon/worldCup/cha.png"
          alt=""
        />
        <div class="select">
          <div class="select_left" @click="ranking_page(0)">
            <p
              :class="rankingPage == 0 ? 'select_leftpco1' : 'select_leftpco'"
              class="select_leftp engBold"
              v-if="decipherment"
            >
              History
            </p>
            <p
              :class="rankingPage == 0 ? 'select_leftpco1' : 'select_leftpco'"
              v-else
              class="select_leftp chinBold"
            >
              历史
            </p>
          </div>
          <div class="select_left" @click="ranking_page(1)">
            <p
              :class="rankingPage == 1 ? 'select_leftpco1' : 'select_leftpco'"
              v-if="decipherment"
              class="select_leftp engBold"
            >
              Ranking
            </p>
            <p
              :class="rankingPage == 1 ? 'select_leftpco1' : 'select_leftpco'"
              v-else
              class="select_leftp chinBold"
            >
              赌王排行榜
            </p>
          </div>
        </div>
        <div class="historied" v-show="rankingPage == 0">
          <div v-for="(item, key) in history_listMe" :key="key">
            <div class="historied_li">
              <div>
                <div class="afkhcn">
                  <img :src="item.event1.url" alt="" />
                </div>
                <div class="nameed nameed_al">
                  <p v-if="decipherment" class="engreg">
                    {{ item.event1.name_en }}
                  </p>
                  <p v-else class="chinreg">
                    {{ item.event1.name }}
                  </p>
                </div>
              </div>
              <div class="vs">
                <p class="vsname engreg">VS</p>
                <p class="time_name engreg">{{ dataTime(item.start_time) }}</p>
                <div
                  :class="
                    item.win_caps >= 0
                      ? 'number_moed engreg'
                      : 'number_mo engreg'
                  "
                >
                  {{ item.win_caps }}
                </div>
              </div>
              <div>
                <div class="afkhcn">
                  <img :src="item.evnet2.url" alt="" />
                </div>
                <div class="nameed nameed_aled engreg">
                  <p v-if="decipherment" class="engreg">
                    {{ item.evnet2.name_en }}
                  </p>
                  <p v-else class="chinreg">
                    {{ item.evnet2.name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="historied_li_m">
              <div class="processed_mlileft">
                <p class="left_img">
                  <img :src="item.event1.url" alt="" />
                </p>
                <div class="left_name">
                  <p v-if="decipherment" class="engreg">
                    {{ item.event1.name_en }}
                  </p>
                  <p v-else class="chinreg">
                    {{ item.event1.name }}
                  </p>
                </div>
              </div>
              <div class="processed_mlivs">
                <p class="left_vs engreg">VS</p>
                <p class="left_time engreg">{{ dataTime(item.start_time) }}</p>
                <p
                  :class="
                    item.win_caps >= 0 ? 'left_win engreg' : 'left_lose engreg'
                  "
                >
                  {{ item.win_caps }}
                </p>
              </div>
              <div class="processed_mlileft">
                <p class="left_img">
                  <img :src="item.evnet2.url" alt="" />
                </p>
                <div class="left_name">
                  <p v-if="decipherment" class="engreg">
                    {{ item.evnet2.name_en }}
                  </p>
                  <p v-else class="chinreg">
                    {{ item.evnet2.name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="xian"></div>
          </div>
        </div>
        <div class="rankinged" v-show="rankingPage == 1">
          <div class="left">
            <div class="left_title engreg">
              <p v-if="decipherment" class="engreg">Profit</p>
              <p v-else class="chinreg">总利润</p>
            </div>
            <div
              class="left_box"
              v-for="(item, key) in ranking_list.caps_rank"
              :key="key"
            >
              <p class="centertu">
                <span class="index engreg">{{ key + 1 }}</span>
                <span class="adree engreg">{{
                  item.chain_wallet_id.substr(0, 5) +
                  "****" +
                  item.chain_wallet_id.substr(-4)
                }}</span>
                <span
                  class="chipteu"
                  :class="item.chip < 0 ? 'num engreg' : 'numre engreg'"
                >
                  <span v-if="item.chip < 0">{{ parseInt(item.chip) }}</span>
                  <span v-else>+{{ parseInt(item.chip) }}</span>
                </span>
              </p>
              <p
                class="chipfal centertu"
                :class="item.chip < 0 ? 'num engreg' : 'numre engreg'"
              >
                <span v-if="item.chip < 0">{{ parseInt(item.chip) }}</span>
                <span v-else>+{{ parseInt(item.chip) }}</span>
              </p>
            </div>
          </div>
          <div class="xian"></div>
          <div class="left">
            <div class="left_title">
              <p v-if="decipherment" class="engreg">Profit Rate</p>
              <p v-else class="chinreg">总利润率</p>
            </div>
            <div
              class="left_box"
              v-for="(item, key) in ranking_list.ratio_rank"
              :key="key"
            >
              <p class="centertu">
                <span class="index engreg">{{ key + 1 }}</span>
                <span class="adree engreg">{{
                  item.chain_wallet_id.substr(0, 5) +
                  "****" +
                  item.chain_wallet_id.substr(-4)
                }}</span>
                <span
                  class="chipteu"
                  :class="item.chip < 0 ? 'num engreg' : 'numre engreg'"
                >
                  <span v-if="item.chip < 0">{{ item.chip.toFixed(2) }}%</span>
                  <span v-else>+{{ item.chip.toFixed(2) }}%</span>
                </span>
              </p>
              <p
                class="chipfal centertu"
                :class="item.chip < 0 ? 'num engreg' : 'numre engreg'"
              >
                <span v-if="item.chip < 0">{{ item.chip.toFixed(2) }}%</span>
                <span v-else>+{{ item.chip.toFixed(2) }}%</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { connectWallet, getCurrentAccount } from "@/utils/login.js";
import {
  world_cup_history,
  world_cup_active,
  world_cup_account_info,
  world_cup_account_history,
  world_cup_rank,
  world_cup_betting,
} from "@/api/peking/index.js";
import { submit_login } from "@/api/login/index.js";
import { Message } from "element-ui";

export default {
  name: "Music",
  data() {
    return {
      walletAddress: "",
      currentAccountnum: "",
      connected: false,
      hinttr: false,
      revise_text: false,
      chin: "中文",
      eng: "ENGLISH",
      decipherment: true,
      menudel: false,
      selectPage: 0,
      bettingBox: false,
      cap_number: null,
      rankingPage: 0,
      rankingBox: false,
      progress_list: [],
      history_list: [],
      ranking_list: [],
      history_listMe: [],
      information_list: "",
      sessions_id: null,
      event_id: null,
      caps_true: true,
    };
  },
  watch: {
    decipherment: function (oldValue) {
      if (oldValue == true) {
        this.chin = "中文";
        this.eng = "ENGLISH";
      } else {
        this.chin = "ENGLISH";
        this.eng = "中文";
      }
    },
  },
  computed: {
    dataTime() {
      return function (t) {
        return this.secondsFormat(t);
      };
    },
  },
  mounted() {
    if (this.$route.params.decipherment) {
      this.decipherment = true;
    } else {
      this.decipherment = false;
    }
    console.log(this.decipherment);
    this.watchWeb3Event();
    this.checkLogIn();
  },
  methods: {
    valueChange(event) {
      console.log(this.information_list.total_caps < this.cap_number);
      if (this.information_list.total_caps < this.cap_number) {
        this.caps_true = false;
      } else {
        this.caps_true = true;
      }
      this.cap_number = event.target.value;
    },
    async worldCupBetting(e) {
      if (e) {
        if (this.cap_number == 0) {
          Message({
            message: "请输入正确的瓶盖数！",
            duration: 3 * 1000,
            type: "error",
          });
        } else {
          let params = {
            id: this.sessions_id,
            event_id: this.event_id,
            caps: Number(this.cap_number),
          };
          let res = await world_cup_betting(params);
          console.log(res);
          if (res.code == 200) {
            this.bettingBox = false;
            this.information();
            this.progressList();
          }
          console.log("队伍id：", params);
        }
      }
      console.log("输入瓶盖数：", this.cap_number);
      console.log("场次id：", this.sessions_id);
      console.log("队伍id：", this.event_id);
    },
    async progressList() {
      let res = await world_cup_active();
      console.log(res);
      if (res.code == 200) {
        this.progress_list = res.data;
      }
    },
    async historyList() {
      let res = await world_cup_history();
      console.log(res);
      if (res.code == 200) {
        this.history_list = res.data;
      }
    },
    async historyListMe() {
      let res = await world_cup_account_history();
      console.log(res);
      if (res.code == 200) {
        this.history_listMe = res.data;
      }
    },
    async rankingList() {
      let res = await world_cup_rank();
      console.log(res);
      if (res.code == 200) {
        this.ranking_list = res.data;
      }
    },
    history_true() {
      this.rankingPage = 0;
      this.historyListMe();
      this.rankingBox = true;
    },
    ranking_true() {
      this.rankingPage = 1;
      this.rankingList();
      this.rankingBox = true;
    },
    close_his() {
      this.rankingBox = false;
    },
    betting_Box(e, id, event_id) {
      if (e != 0) {
        this.cap_number = null;
        this.bettingBox = true;
        this.sessions_id = id;
        this.event_id = event_id;
      }
    },
    close() {
      this.bettingBox = false;
    },
    twitter() {
      window.open("https://twitter.com/Peking_Monsters");
    },
    redirect() {
      window.open("https://discord-verify.pekingmonsters.xyz/v2/redirect");
    },
    openseaview() {
      window.open("https://opensea.io/collection/pekingmonsters");
    },
    menu() {
      this.menudel = !this.menudel;
    },
    party() {
      this.$router.push({
        name: "party",
        params: { decipherment: this.decipherment },
      });
    },
    changeCloth() {
      this.$router.push({
        name: "worldCupCloth",
        params: { decipherment: this.decipherment },
      });
    },
    index() {
      this.$router.push({
        name: "mint",
        params: { decipherment: this.decipherment },
      });
    },
    stake() {
      this.$router.push({
        name: "stake",
        params: { decipherment: this.decipherment },
      });
    },
    refresh() {
      location.reload();
    },
    starry() {
      this.$router.push({
        name: "3DPARTY",
        params: { decipherment: this.decipherment },
      });
    },
    revise() {
      this.revise_text = !this.revise_text;
    },
    deciphermented() {
      this.decipherment = !this.decipherment;
    },
    hint() {
      if (window.screen.width < 1024) {
        this.hinttr = !this.hinttr;
      }
    },
    select_page(e) {
      this.selectPage = e;
      if (e == 0) {
        this.progressList();
      } else {
        this.historyList();
      }
    },
    ranking_page(e) {
      this.rankingPage = e;
      if (e == 0) {
        this.historyListMe();
      } else {
        this.rankingList();
      }
    },
    async getToken() {
      let data = {
        chain_wallet_id: this.currentAccountnum,
      };
      let res = await submit_login(data);
      if (res.code == 200) {
        localStorage.setItem("token", res.data.token);
      }
    },
    async information() {
      this.getToken();
      let res = await world_cup_account_info();
      if (res.code == 200) {
        this.information_list = res.data;
      }
    },
    async logIn() {
      let currentAccount;
      try {
        currentAccount = await getCurrentAccount();
        this.currentAccountnum = currentAccount;
      } catch (error) {
        var herf = window.location.hostname;
        window.open("https://metamask.app.link/dapp/" + herf);
      }
      if (currentAccount == null) {
        await connectWallet();
      }
      this.connected = true;
      this.information();
      this.progressList();
    },
    async checkLogIn() {
      let currentAccount = await getCurrentAccount();
      this.currentAccountnum = currentAccount;
      if (currentAccount != undefined) {
        // this.ListMyItem();
        this.walletAddress =
          currentAccount.substr(0, 5) + "****" + currentAccount.substr(-4);
      }
      if (currentAccount) {
        this.connected = true;
        if (localStorage.getItem("wallet_address") != currentAccount) {
          localStorage.setItem("wallet_address", currentAccount);
        }
        this.information();
        this.progressList();
      }
    },
    watchWeb3Event() {
      var that = this;
      ethereum.on("disconnect", (res) => {
        console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        localStorage.setItem("wallet_address", res[0]);
        that.currentAccountnum = res[0];
        that.information();
        that.progressList();
        that.selectPage = 0;
        if (res[0]) {
          that.walletAddress = res[0].substr(0, 5) + "****" + res[0].substr(-4);
          that.connected = true;
        } else {
          that.connected = false;
          that.walletAddress = "";
        }
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        console.log(`networkID`, res, network);
        that.$store
          .dispatch("web3/set_network", network)
          .then((res) => {
            if (
              network == process.env.VUE_APP_MAIN_NETWORK ||
              network == process.env.VUE_APP_MAIN_NETWORK2
            ) {
              that.$store.dispatch("mask/closeMask", "mainNetwork");
              //  this.connectMetaMask()
              console.log("已切换到连接主网络");
              // window.location.reload();
            } else {
              that.$store.dispatch("mask/openMask", "mainNetwork");
            }
          })
          .catch((err) => {});
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
      // alert("成功");
    },
    secondsFormat(t) {
      let time3 = new Date(t).getTime() * 1000;
      var date = new Date(time3);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";

      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var strDate = Y + M + D + h + m + s;
      return strDate;
    },
  },
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>
